import { type CrossSellResponse } from '@redteclab/api/clients/retail-media-xsell-service'

import { useCrossSellConfigContext } from './components/CrossSellConfigProvider'
import { type CrossSellWidgetType } from './config/CrossSellWidgetType'

/**
 * Returns a human-readable name for a widget, if provided in the configuration {@link crossSellGetConfiguration}.
 * Econda integration does not support multilingual shops.
 * For more details, see: https://jira.shop-apotheke.com/browse/WSAWA-5986
 *
 * @returns The widget title from the configuration if available; otherwise, the title from the response.
 */
export const useCrossSellWidgetTitle = ({
  data,
  widgetId,
  widgetType,
}: {
  data: CrossSellResponse | undefined
  widgetId?: string
  widgetType?: CrossSellWidgetType
}): string | undefined => {
  const { widgets } = useCrossSellConfigContext()

  // Don't display title until `data` is fetched (even if we have predefined title in config) to properly show skeleton
  if (!data) {
    return undefined
  }

  if (widgetType && widgets[widgetType]?.title) {
    return widgets[widgetType].title
  }

  if (widgetId) {
    const widgetForId = Object.values(widgets).find(
      (widget) => String(widget.id) === widgetId,
    )

    if (widgetForId?.title) {
      return widgetForId.title
    }
  }

  return data.widgetdetails.title
}
