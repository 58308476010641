import { clsx } from 'clsx'
import { type FC } from 'react'

import { useGlobalConfigContext } from '../../global-config'
import { TENANT } from '../../tenant'

const PRODUCT_RATING_CALCULATION_SUPER_SCRIPT_COM = 27

export const TosSuperscriptHowProductRatingCalculated: FC<{
  className?: string
}> = ({ className }) => {
  const { tenant } = useGlobalConfigContext()

  if (tenant === TENANT.COM) {
    return (
      <sup className={clsx(className)}>
        {PRODUCT_RATING_CALCULATION_SUPER_SCRIPT_COM}
      </sup>
    )
  }

  return null
}
