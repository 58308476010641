import { clsx } from 'clsx'
import { type FC, type PropsWithChildren } from 'react'

import { Skeleton } from 'base-ui'

type CrossSellHorizontalSliderWidgetTitleProps = PropsWithChildren<{
  className?: string
}>

export const CrossSellWidgetTitle: FC<
  CrossSellHorizontalSliderWidgetTitleProps
> = ({ children, className }) => {
  if (!children) {
    return (
      <Skeleton
        className={clsx('cross-sell-widget-title_skeleton', className)}
        slots={{ root: 'h2' }}
      />
    )
  }

  return <h2 className={className}>{children}</h2>
}
