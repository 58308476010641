'use client'

import { useCallback, useState } from 'react'

export const useCrossSellIntersection = (): {
  handleIntersection: () => void
  intersected: boolean
} => {
  const [intersected, setIntersected] = useState(false)

  const handleIntersection = useCallback(() => {
    setIntersected(true)
  }, [])

  return { handleIntersection, intersected }
}
