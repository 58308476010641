import { clsx } from 'clsx'
import { type FC, type PropsWithChildren } from 'react'

import { IconArrowRight } from '@redteclab/icons'

import { Link, Skeleton } from 'base-ui'

type CrossSellHorizontalSliderWidgetMoreLinkProps = PropsWithChildren<{
  className?: string
  href?: string
}>

export const CrossSellWidgetMoreLink: FC<
  CrossSellHorizontalSliderWidgetMoreLinkProps
> = ({ children, className, href }) => {
  if (!href) {
    return (
      <Skeleton
        className={clsx(
          'cross-sell-widget-more-link cross-sell-widget-more-link_skeleton',
          className,
        )}
      />
    )
  }

  return (
    <Link
      className={clsx('cross-sell-widget-more-link', className)}
      color="inherit"
      href={href}
    >
      {children}
      <IconArrowRight className="size-6 text-dark-brand" />
    </Link>
  )
}
