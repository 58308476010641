import { useCallback } from 'react'
import { useSessionStorage } from 'usehooks-ts'

export const SEARCH_INTERACTED_KEY = 'searchInteracted'
export const CSW_INTERACTED_KEY = 'cswInteracted'

export const useSetSessionStorageValuesForOptimizelyTracking = (
  key: string,
  initialValue = false,
): (() => void) => {
  const [interacted, setInteracted] = useSessionStorage(key, initialValue)

  return useCallback(() => {
    if (!interacted) {
      setInteracted(true)
    }
  }, [interacted, setInteracted])
}
