'use client'

import { useEffect, useState } from 'react'

/**
 * Slider functionality works only on client-side. Customization is done using HTML attribute `hidden`.
 */
// eslint-disable-next-line @typescript-eslint/no-unnecessary-type-parameters -- probably false positive as T is used in return structure and once in code itself
export const useSliderNavigation = <T extends HTMLElement>(): {
  containerRef: (element: T) => void
  leftRef: (element: HTMLButtonElement) => void
  rightRef: (element: HTMLButtonElement) => void
} => {
  const [container, setContainer] = useState<T | null>(null)
  const [leftButton, setLeftButton] = useState<HTMLButtonElement | null>(null)
  const [rightButton, setRightButton] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (!container) {
      return undefined
    }

    /**
     * If slider items have a gap between we take this value in runtime into JavaScript
     * to correct the scroll delta value
     */
    const gap = Number.parseInt(getComputedStyle(container).gap, 10) || 0
    const delta = container.offsetWidth + gap

    const scrollLeft = (): void => {
      container.scrollBy({
        left: -delta,
      })
    }

    const scrollRight = (): void => {
      container.scrollBy({
        left: delta,
      })
    }

    const checkVisibility = (): void => {
      if (leftButton) {
        leftButton.hidden = container.scrollLeft === 0
      }

      if (rightButton) {
        rightButton.hidden =
          Math.round(container.scrollLeft + container.offsetWidth) + 1 >=
          container.scrollWidth
      }
    }

    checkVisibility()

    container.addEventListener('scroll', checkVisibility, {
      passive: true,
    })

    leftButton?.addEventListener('click', scrollLeft)
    rightButton?.addEventListener('click', scrollRight)

    return (): void => {
      container.removeEventListener('scroll', checkVisibility)
      leftButton?.removeEventListener('click', scrollLeft)
      rightButton?.removeEventListener('click', scrollRight)
    }
  }, [container, leftButton, rightButton])

  return {
    containerRef: setContainer,
    leftRef: setLeftButton,
    rightRef: setRightButton,
  }
}
