'use client'

import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type CSSProperties,
  type ElementType,
  forwardRef,
  useMemo,
} from 'react'

import { type CrossSellProduct } from '@redteclab/api/clients/retail-media-xsell-service'

import { useGlobalConfigContext } from '../../../global-config'
import { SliderButton, useSliderNavigation } from '../../../slider'
import { crossSellAccessorGetLocalizedProductDeepLink } from '../../model/crossSellAccessorGetLocalizedProductDeepLink'
import { CrossSellProductCard } from '../CrossSellProductCard'

type CrossSellHorizontalSliderProps = Omit<
  ComponentPropsWithoutRef<'div'>,
  'children'
> & {
  onGtmDataLayerPush?: (cswProductId: CrossSellProduct['id']) => void
  products?: CrossSellProduct[]
  productsPerPage: number
  slots?: {
    itemContent?: ElementType
  }
}

export const CrossSellHorizontalSlider = forwardRef<
  HTMLDivElement,
  CrossSellHorizontalSliderProps
>(function CrossSellHorizontalSlider(
  {
    className,
    onGtmDataLayerPush,
    products,
    productsPerPage,
    slots = {},
    ...rootElemProps
  },
  ref,
) {
  const { language, tenant } = useGlobalConfigContext()

  const items = useMemo(() => {
    if (!products) {
      /**
       * numbers will be returned to show skeleton items
       */
      return [...Array.from({ length: productsPerPage + 1 }).keys()]
    }

    // Filter out products without deeplink. Not possible to display slider item if there is no product link
    return products.filter((product) =>
      crossSellAccessorGetLocalizedProductDeepLink({
        language,
        product,
        tenant,
      }),
    )
  }, [language, products, productsPerPage, tenant])

  const ProductComponent = slots.itemContent ?? CrossSellProductCard

  const { containerRef, leftRef, rightRef } =
    useSliderNavigation<HTMLUListElement>()

  return (
    <div
      className={clsx('cross-sell-horizontal-slider', className)}
      ref={ref}
      {...rootElemProps}
    >
      <ul
        className={clsx(
          'cross-sell-horizontal-slider__slider',
          !products && 'cross-sell-horizontal-slider__slider_no-items',
        )}
        ref={containerRef}
        style={
          {
            '--items-per-page': String(productsPerPage),
          } as CSSProperties
        }
      >
        {items.map((item) => (
          <li
            className="cross-sell-horizontal-slider__item-wrapper"
            key={typeof item === 'number' ? item : item.id}
            onClick={
              onGtmDataLayerPush
                ? (event): void => {
                    if (
                      event.target instanceof HTMLElement &&
                      event.target.closest('a') &&
                      // if item is a number, it's a skeleton item
                      typeof item !== 'number'
                    ) {
                      onGtmDataLayerPush(item.id)
                    }
                  }
                : undefined
            }
            role="presentation"
          >
            <ProductComponent
              product={typeof item === 'number' ? undefined : item}
            />
          </li>
        ))}
      </ul>
      {products !== undefined && products.length > productsPerPage && (
        <>
          <SliderButton
            aria-label="previous"
            className="cross-sell-horizontal-slider__navigation-button left-0 hidden tablet:inline-flex"
            direction="left"
            ref={leftRef}
          />
          <SliderButton
            aria-label="next"
            className="cross-sell-horizontal-slider__navigation-button right-0 hidden tablet:inline-flex"
            direction="right"
            ref={rightRef}
          />
        </>
      )}
    </div>
  )
})
