import { clsx } from 'clsx'
import {
  type ComponentPropsWithoutRef,
  type FC,
  forwardRef,
  type SVGProps,
} from 'react'

import { IconCaretLeft, IconCaretRight } from '@redteclab/icons'

type SliderButtonProps = ComponentPropsWithoutRef<'button'> & {
  direction: 'left' | 'right'
}

const directionIcons: Record<
  SliderButtonProps['direction'],
  FC<SVGProps<SVGSVGElement>>
> = {
  left: IconCaretLeft,
  right: IconCaretRight,
}

/**
 * Slider button component is based on Figma design https://www.figma.com/design/vkkS2UvzF4LqIOXB4x5Xwy/RC-%2B-SAE-Web-Components?node-id=27-515&m=dev.
 * Slider controls work only on client-side and initially should be hidden.
 * Depending on implementation hidden state should be provided on demand based on `hidden` attribute.
 */
export const SliderButton = forwardRef<HTMLButtonElement, SliderButtonProps>(
  function SliderButton(props, ref) {
    const { className, direction, ...rest } = props

    const DirectionIcon = directionIcons[direction]

    return (
      <button
        className={clsx('slider-button', className)}
        hidden
        ref={ref}
        type="button"
        {...rest}
      >
        <DirectionIcon className="slider-button__icon" />
      </button>
    )
  },
)
