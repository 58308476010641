'use client'

import { unstable_useForkRef as useForkRef } from '@mui/utils'
import {
  type ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useRef,
} from 'react'
import { useIntersectionObserver } from 'usehooks-ts'

import { type CrossSellResponse } from '@redteclab/api/clients/retail-media-xsell-service'

import { useCrossSellPreloadedSlotsPush } from '../../../ad-server'
import { useCrossSellMetadataContext } from '../../../cross-sell/components/CrossSellMetatadaProvider'
import { type CrossSellWidgetType } from '../../config/CrossSellWidgetType'
import { useCrossSellWidgetTitle } from '../../useCrossSellWidgetTitle'

type CrossSellHorizontalSliderWidgetProps = ComponentPropsWithRef<'div'> & {
  onIntersect: () => void

  widgetData: CrossSellResponse | undefined
  widgetId: string | number | undefined
  /**
   * Used in browser tests for checking widget by type
   */
  widgetType?: CrossSellWidgetType
}

export const CrossSellWidgetContainer = forwardRef<
  HTMLDivElement,
  CrossSellHorizontalSliderWidgetProps
>(function CrossSellHorizontalSliderWidget(
  { onIntersect, widgetData, widgetId, widgetType, ...restProps },
  forwardedRef,
) {
  const { isIntersecting, ref } = useIntersectionObserver({
    freezeOnceVisible: true,
    rootMargin: '0px 0px 200px',
  })

  useEffect(() => {
    if (isIntersecting) {
      onIntersect()
    }
  }, [isIntersecting, onIntersect])

  const widgetTitle = useCrossSellWidgetTitle({
    data: widgetData,
    widgetType,
  })

  const metadata = useCrossSellMetadataContext()
  const metadataElemenRef = useRef<HTMLDivElement>(null)
  useCrossSellPreloadedSlotsPush(metadataElemenRef.current, metadata)

  return (
    <div
      data-analytics={widgetData ? 'crosssell-widget' : undefined}
      data-crosssell-id={widgetData ? widgetId : undefined}
      data-crosssell-title={widgetTitle}
      data-qa-id="cross-sell-widget"
      data-qa-widget-type={widgetType}
      ref={useForkRef(ref, forwardedRef, metadataElemenRef)}
      {...restProps}
    />
  )
})
