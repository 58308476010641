import { type ImageLoader, type ImageLoaderProps } from 'next/image'

import { useGlobalConfigContext } from '../../global-config'

const getNormalizeFileName = (fileName: string, cdnUrlLength: number): string =>
  fileName.slice(cdnUrlLength).replace(/^\d+x\d+\//u, '')

const imageLoader = (
  cdnUrl: string,
  { src, width }: ImageLoaderProps,
): string => {
  if (!src.startsWith(cdnUrl)) {
    return src
  }

  const normalizeFileName = getNormalizeFileName(src, cdnUrl.length)
  const urlWithSize = new URL(`${width}x0/`, cdnUrl)
  const urlWithSizeAndFileName = new URL(
    normalizeFileName,
    urlWithSize.toString(),
  )

  return urlWithSizeAndFileName.toString()
}

export const useCdnHostedImageLoader = (): ImageLoader => {
  const { cdn } = useGlobalConfigContext()

  return (imageLoaderProps: ImageLoaderProps) => {
    return imageLoader(
      new URL('/images/', cdn.url).toString(),
      imageLoaderProps,
    )
  }
}
