import { type IntlShape } from '@formatjs/intl'
import {
  type FormatNumberOptions,
  type IntlShape as ReactIntlShape,
} from 'react-intl'

const getCurrencySymbol = (
  currency: string,
  intl: IntlShape | ReactIntlShape,
): string =>
  intl
    .formatNumber(0, {
      currency,
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
      style: 'currency',
    })
    .replaceAll(/\d/gu, '')
    .trim()

const DEFAULT_FRACTION_DIGITS = 2

const MINUS = '−'

const NON_BREAKING_SPACE = '\u00A0'

/**
 * In some cases price data doesn't have currency. Decision to rely on
 * current locale is done under https://jira.shop-apotheke.com/browse/WSAWA-4431.
 */
export const getCurrencyForLocale = (locale: string): string => {
  if (locale.includes('CH')) {
    return 'CHF'
  }

  return 'EUR'
}

/**
 * Price format:
 * ```
 * ┌─ minus sign
 * │ ┌─ currency sign (€) or code (CHF)
 * │ │  ┌─ integer
 * │ │  │ ┌─ decimal (depends on locale)
 * │ │  │ │ ┌─ fraction
 * │ │  │ │ │
 * - € 123,45
 * ```
 *
 * Why `Intl.NumberFormat` doesn't work:
 * - currency position is different across locales
 * - spacing between minus, currency and number is different across locales
 */
export const priceGetFormattedPrice = (
  value: number,
  intl: IntlShape | ReactIntlShape,
  {
    currency = getCurrencyForLocale(intl.locale),
    maximumFractionDigits = DEFAULT_FRACTION_DIGITS,
    minimumFractionDigits = DEFAULT_FRACTION_DIGITS,
  }: FormatNumberOptions = {},
): string => {
  const absoluteAmount = Math.abs(value)

  const price = intl.formatNumber(absoluteAmount, {
    maximumFractionDigits,
    minimumFractionDigits,
  })

  const currencySymbol = getCurrencySymbol(currency, intl)

  const pricesParts = [currencySymbol, price]

  if (value < 0) {
    pricesParts.unshift(MINUS)
  }

  return pricesParts.join(NON_BREAKING_SPACE)
}
