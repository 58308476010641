'use client'

import { useEffect } from 'react'

import { type Metadata } from '@redteclab/api/clients/retail-media-xsell-service'

import { AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR } from './adServerConst'
import { adServerSlotsPushSetupPreloadedSlotsCommand } from './adServerSlots'
import { type AdServerSetupPreloadedSlot } from './model/adServerTypes'

export const useCrossSellPreloadedSlotsPush = (
  rootElement: Element | null,
  metadata?: Metadata,
): void => {
  useEffect(() => {
    if (rootElement && metadata) {
      /*
       * Collect all re-ranked / injected elements and push them into ad server data layer.
       * Intersection observer on the ad server client triggers action when
       * user scrolls / has cross sell in the browser window view.
       */
      const crossSellItemElements = [
        ...rootElement.querySelectorAll(
          `[${AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR}]`,
        ),
      ]

      if (crossSellItemElements.length > 0) {
        const slotMetadataContentPair: AdServerSetupPreloadedSlot[] = []

        for (const element of crossSellItemElements) {
          const productId = element.getAttribute(
            AD_SERVER_XSELL_PRODUCT_ID_DATA_ATTR,
          )
          const metadataContent = metadata[productId as keyof Metadata]

          if (metadataContent && productId) {
            slotMetadataContentPair.push({
              metadataContent: {
                ...metadataContent,
                productId,
              },
              slot: element,
            })
          }
        }

        if (slotMetadataContentPair.length > 0) {
          adServerSlotsPushSetupPreloadedSlotsCommand(slotMetadataContentPair)
        }
      }
    }
  }, [rootElement, metadata])
}
